<template>
  <v-container fluid>
    <v-row>
      <v-col class="text-center">
        <h2 class="text-h3 d-inline-block">
          Zugriff nicht erlaubt
        </h2>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>
